import {adminApi, authApi} from "../aaxios";
import {useEffect, useState} from "react";

async function uploadPdfRequest(formData) {
    try {
        const res = await authApi.post('/admin/pdf/upload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        return res['data']
    } catch (e) {
        alert("파일 업로드 실패")
    }
}

async function listPdfReq() {
    try {
        const url = `/admin/pdf/list?page=${1}&page_size=${10}`
        const res = await authApi.get(url)
        return res['data']['data']
    } catch (e) {
    }
}

async function deletePdfRequest(pdf) {
    try {
        const url = `/admin/pdf/${pdf.id}`
        const res = await authApi.delete(url)
        return res['data']
    } catch (e) {
        alert("파일 삭제 실패")
    }
}

async function getServiceFileRequest(serviceFileId) {
    try {
        const url = `/admin/pdf/${serviceFileId}/status`
        const res = await authApi.get(url)
        return res['data']['data']
    } catch (e) {
        alert("파일 단건 조회 실패")
    }
}


export default function useLoadPdf() {
    const [pdfList, setPdfList] = useState([])

    useEffect(() => {
        listPdfReq().then((data) => {
            const newList = [...data['page']]
            setPdfList(newList)
            watchPdfStatus(newList)
        })
    }, []);


    function watchPdfStatus(dataList) {
        dataList.map(pdf => {
            if (pdf['status'] === 'processing' || pdf['status'] === 'uploaded') {
                const uploadFileStatusInterval = setInterval(async () => {
                    const newRes = await getServiceFileRequest(pdf.id)
                    setPdfList(prevFiles => {
                        return prevFiles.map((file) => {
                            return file.id === pdf.id ? newRes : file
                        })
                    })
                    if (newRes['status'] !== "processing") {
                        clearInterval(uploadFileStatusInterval);
                    }
                }, 5000)
            }
        })
    }


    async function uploadPdf(formData) {
        const res = await uploadPdfRequest(formData)
        if (res.ok) {
            const newList = [...res['data'], ...pdfList]
            setPdfList(newList)
            watchPdfStatus(newList)
            return {
                ok: true
            }
        }
        return {
            ok: false
        }
    }

    async function deletePdf(pdf) {
        const res = await deletePdfRequest(pdf)
        if (res.ok) {
            setPdfList(pdfList.filter(p => p.id !== pdf.id))
        }
    }

    return {
        pdfList,
        uploadPdf,
        deletePdf
    }
}