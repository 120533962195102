import React, {useEffect, useState} from 'react';
import {adminApi} from "../aaxios";


async function addFaqRequest(data) {
    const {
        content,
    } = data
    try {
        const res = await adminApi.post('/admin/faq', {
            content: content,
            title: content
        })
        return res.data['data']
    } catch (error) {
        alert("FAQ 생성 중 에러 발생!");
    }
}


async function listFaqRequest(data) {

    try {
        const url = `/faq/list?page=${1}&page_size=${100}`
        const res = await adminApi.get(url)
        return res.data['data']
    } catch (error) {
        alert("FAQ 생성 중 에러 발생!");
    }
}

async function deleteFaqRequest(data) {

    try {
        const url = `/admin/faq/${data.id}`
        const res = await adminApi.delete(url)
        return res.data['data']
    } catch (error) {
        alert("FAQ 삭제 에러 발생!");
    }
}

export default function useLoadFAQ() {
    const [faqList, setFAQList] = useState([])

    async function addFaq(content) {
        const res = await addFaqRequest({content})
        if (res['id']) {
            setFAQList([res, ...faqList])
            return {
                ok: true
            }
        }
        return {
            ok: false,
            message: "생성에 실패 했습니다."
        }
    }

    async function deleteFqa(faq) {
        await deleteFaqRequest(faq)
        setFAQList(faqList.filter(f => f.id !== faq.id))
    }

    useEffect(() => {
        listFaqRequest().then((data) => {
            setFAQList(data['page'])
        })
    }, []);

    return {
        faqList,
        addFaq,
        deleteFqa
    }
}