import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomePage from './Pages/Home';
import LoginPage from './Pages/Login';
import MainPage from "./Pages/Main";


function App() {

    return (
        <div className="App" id='Apps'>
            {/* 페이지 구분 */}
            <Router>
                <Routes>
                    {/* 인트로, 로그인 */}
                    <Route path='/' element={<LoginPage/>}/>
                    {/* 홈 */}
                    <Route path='/home' element={<HomePage/>}/>
                    {/* 질문이력 관리 */}
                    <Route path='/main' element={<><MainPage/></>}/>
                </Routes>
            </Router>
        </div>
    )
}

// function Login(){
//   // window.location.href = "/main";

//   // cors 정책으로 백엔드 요청 못함(추후 수정)
//   const idValue = document.getElementById('inputID').value;
//   const passwordValue = document.getElementById('inputPassword').value;

//   axios.post('https://viewbeauty-admin.o2o.kr/api/v1/login', {
//     userId: idValue,
//     userPassWord: passwordValue
//     })
//     .then((response) => {
//         console.log('response', response);
//         const loginChk = response.data["loginChk"]
//         console.log('loginChk', loginChk);

//         if (loginChk===true){
//           // /main으로 페이지 이동
//           // history.push('/main');
//           // Login 함수 내에서 값 저장
//           sessionStorage.setItem('idValue', idValue);

//           // window.location.href = "/main"; // 원본
//           window.location.href = "/home"; // 수정 TODO

//         }else{
//           // 로그인 실패
//           alert("아이디 또는 비밀번호를 확인해주세요.");
//           void 0;
//         }

//     })
//     .catch(() => {
//         console.log('false');
//     });
// }


// function GetTotalPageCnt(status){
//   axios.get('https://viewbeauty-admin.o2o.kr/api/v1/session/get_list?'+"page="+'1'+"&status="+status)
//   .then((response)=>{
//     let page_cnt = response.data;
//     page_cnt = page_cnt.total_page_cnt;
//     // console.log(status+'_total_page_cnt >> ', typeof page_cnt, page_cnt);
//     return page_cnt;
//   })
//   // 예외처리
//   .catch(()=>{
//     console.log('false');
//   })
// }

// function GetList(page_num, status){
//   // document.getElementById('table_frame').style.width = '100%';
//   // document.getElementById('detail_content').style.display = 'none';
//   $("#board_content").empty();


//   // 기존 페이지네이션 내용 삭제
//   // $("#page_number").empty();

//   // status에 따른 total page cnt 구하기
//   let total_page_cnt = GetTotalPageCnt(status)
//   // console.log(status+'_total_page_cnt >> ', typeof total_page_cnt, total_page_cnt);

//   // send parameter to backend
//   axios.get('https://viewbeauty-admin.o2o.kr/api/v1/session/get_list?'+"page="+page_num+"&status="+status)

//   .then((response)=>{
//     console.log('response >> ', response);

//     let contents = response.data;
//     contents = contents.result;
//     console.log('게시판 컨텐츠 >> ', typeof contents, contents);

//     // let page_cnt = response.data;
//     // page_cnt = page_cnt.total_page_cnt;
//     // console.log('페이지 네이션 #1 >> ', typeof page_cnt, page_cnt);

//     for (let i = 0; i< contents.length; i++){
//       var each_data = contents[i];
//       // console.log('each_data : ', each_data);

//       let session_id = each_data['session_id']
//       let content = each_data['content']
//       let date = each_data['date']
//       let time = each_data['time']
//       let status = each_data['status']
//       let user_name = each_data.hasOwnProperty('user_name') ? each_data['user_name'] : 'X';
//       let user_number = each_data.hasOwnProperty('user_number') ? each_data['user_number'] : 'X';

//       var app_id = 'board_content';
//       // status check
//       if (status==='수정 필요'){
//         document.getElementById(app_id).innerHTML+=`
//           <tr key=${session_id} class='each_tr_frame' onClick="GetDetail('${session_id}', '${i+1}', '${date}', '${time}')">
//               <td class='col_width_basic' style='color:var(--black, #000);'>${i+1}</td>
//               <td class='col_width_content' style='color:var(--black, #000);'>${content}</td>
//               <td class='col_width_basic' style='color:var(--black, #000);'>${date}</td>
//               <td class='col_width_basic' style='color:var(--black, #000);'>${time}</td>
//               <td class='col_width_basic' style='color:var(--red, #DB524C);'>${status}</td>
//               ${user_name ? `<td class='col_width_basic' style='color:var(--black, #000);'>${user_name}</td>` : ''}
//               ${user_number ? `<td class='col_width_basic' style='color:var(--black, #000);'>${user_number}</td>` : ''}
//           </tr>
//         `;
//       }else{
//         document.getElementById(app_id).innerHTML+=`
//           <tr key=${session_id} class='each_tr_frame' onClick="GetDetail('${session_id}', '${i+1}', '${date}', '${time}')">
//               <td class='col_width_basic' style='color:var(--black, #000);'>${i+1}</td>
//               <td class='col_width_content' style='color:var(--black, #000);'>${content}</td>
//               <td class='col_width_basic' style='color:var(--black, #000);'>${date}</td>
//               <td class='col_width_basic' style='color:var(--black, #000);'>${time}</td>
//               <td class='col_width_basic' style='color:var(--black, #000);'>${status}</td>
//               ${user_name ? `<td class='col_width_basic' style='color:var(--black, #000);'>${user_name}</td>` : ''}
//               ${user_number ? `<td class='col_width_basic' style='color:var(--black, #000);'>${user_number}</td>` : ''}
//           </tr>
//         `;
//       }
//     }

//   })
//   // 예외처리
//   .catch(()=>{
//     console.log('false')
//   })
// }

// function PageNumber({ pageNum, onGetList, isActive }) {
//   return (
//     <p
//       className={`each_page_num ${isActive ? 'selected_page' : ''}`}
//       onClick={() => onGetList(pageNum)}
//     >
//       {pageNum}
//     </p>
//   );
// }


// function PageNationInitial(){

//   axios.get('https://viewbeauty-admin.o2o.kr/api/v1/session/get_list?'+"page="+1)

//   .then((response)=>{
//     console.log('response!! >> ', response);
//     let page_cnt = response.data;
//     page_cnt = page_cnt.total_page_cnt;
//     console.log('페이지 네이션(초기) >> ', typeof page_cnt, page_cnt);
//     // return page_cnt;

//     // 페이지 네이션
//     var page_id = 'page_number';

//     for (let i = 0; i< page_cnt; i++){
//       return <PageNumber pageNum={i+1} onGetList={GetList} />
//     }

//   })
//   // 예외처리
//   .catch(()=>{
//     console.log('false')
//   })

// }


// function PageContainer({ tabType }) {
//   // 수정 버전
//   const [page, setPage] = useState(1); // 현재 페이지 번호
//   const [pageCnt, setPageCnt] = useState(0); // 전체 페이지 수

//   const btnRange = 10; // 보여질 페이지 버튼의 개수
//   const currentSet = Math.ceil(page / btnRange); // 현재 버튼이 몇번째 세트인지 나타내는 수
//   const startPage = (currentSet - 1) * btnRange + 1; // 현재 보여질 버튼의 첫번째 수
//   const endPage = startPage + btnRange - 1; // 현재 보여질 끝 버튼의 수
//   const totalSet = Math.ceil(pageCnt / btnRange); // 전체 버튼 세트 수

//   // 기존 페이지네이션 내용 삭제
//   // $("#page_number").empty();

//   useEffect(() => {
//     // tabType을 URL에 포함시켜 API 호출을 탭에 따라 다르게 할 수 있습니다.
//     axios.get(`https://viewbeauty-admin.o2o.kr/api/v1/session/get_list?page=1&status=${tabType}`)
//       .then((response) => {
//         let page_cnt = response.data.total_page_cnt;
//         setPageCnt(page_cnt);
//         console.log(tabType,'의 페이지수 : ',page_cnt);
//       })
//       .catch(() => {
//         console.log('false');
//       })
//   }, [tabType]);  // tabType의 값이 변경될 때마다 useEffect 내부의 로직이 다시 실행됩니다.


//   return (
//     <div id='page_number'>
//       {currentSet > 1 && (
//         <button
//           className='page-prev'
//           onClick={() => {
//             setPage(startPage - 1);
//             GetList(page - 1, tabType);
//           }}
//           // $active={page > 0 ? true :false}
//         >
//           이전
//         </button>
//       )}

//       {Array.from({ length: btnRange }, (_, i) => {
//         const pageNumber = startPage + i;
//         // 페이지 번호가 전체 페이지 수를 초과하지 않을 때만 렌더링
//         return pageNumber <= pageCnt && (
//           <PageNumber
//             key={i}
//             pageNum={pageNumber}
//             onGetList={() => {
//               setPage(pageNumber);
//               GetList(pageNumber, tabType);
//             }}
//             isActive={pageNumber === page} // 현재 페이지 여부 확인
//           />
//         );
//       })}

//       {totalSet > currentSet && (
//         <button
//           className='page-next'
//           onClick={() => {
//             setPage(endPage + 1);
//             GetList(page + 1, tabType);
//           }}
//           // $active={page < pageCnt ? true :false}
//         >
//           다음
//         </button>
//       )}
//     </div>
//   );
// }


// // let default_page_num = 1
// // console.log('default_page_num >>', default_page_num);
// // let page_cnt = GetList(default_page_num);

// // console.log('page_cnt!! >>', page_cnt);
// // PageNation(page_cnt);

// let default_page_num = 1
// let default_status = 'all'

// GetList(default_page_num, default_status);  // await keyword added
// PageNationInitial();


// function SelectTapHeader(EcahTapName){
//   var target_num = 'tap_header_'+EcahTapName;
//   document.getElementById('tap_header_all').style.background = 'none';
//   document.getElementById('tap_header_check').style.background = 'none';
//   document.getElementById('tap_header_edit').style.background = 'none';
//   document.getElementById('tap_header_done').style.background = 'none';

//   document.getElementById(target_num).style.background = '#7C2B84';


//   console.log('tap color change');

// }


// function MainContainer(){

//   // 탭선택에 따라 상태값을 페이지 선택 항목에 전달
//   const [selectedTab, setSelectedTab] = useState('all');  // 기본값은 'all'
//   const handleTabClick = (tabType) => {
//         setSelectedTab(tabType);
//     };


//   // /main 페이지에서 값 가져오기
//   const retrievedIdValue = sessionStorage.getItem('idValue');
//   console.log('retrievedIdValue:',retrievedIdValue);


//     return (
//       <div className='wrap_home'>
//         {/* 헤더 */}
//         <div className='wrap_header'>
//           <div>
//               {/* 탭 헤더 */}
//               <div className='tap_header' id='tap_header_all' style={{background: 'var(--view-03, #7C2B84);', borderRadius: '0 0 20px 20px'}} onClick={()=>{SelectTapHeader('all'); handleTabClick('all'); GetList(1,'all');}}>전체</div>
//               <div className='tap_header' id='tap_header_check' style={{background: 'var(--view-03, #7C2B84);', borderRadius: '0 0 20px 20px'}} onClick={()=>{SelectTapHeader('check'); handleTabClick('check'); GetList(1,'check');}}>미확인</div>
//               <div className='tap_header' id='tap_header_edit' style={{background: 'var(--view-03, #7C2B84);', borderRadius: '0 0 20px 20px'}} onClick={()=>{SelectTapHeader('edit'); handleTabClick('edit'); GetList(1,'edit');}}>수정 필요</div>
//               <div className='tap_header' id='tap_header_done' style={{background: 'var(--view-03, #7C2B84);', borderRadius: '0 0 20px 20px'}} onClick={()=>{SelectTapHeader('done'); handleTabClick('done'); GetList(1,'done');}}>검토 완료</div>
//           </div>
//         </div>

//         {/* 게시판 */}
//         <div className='wrap_board' id='wrap_board'>

//           <table style={{width: '95%'}} className='table_frame' id='table_frame'>
//               <thead className='thead_basic'>
//                   <tr>
//                       <th className='col_width_basic'>글번호</th>
//                       <th className='col_width_content'>내용</th>
//                       <th className='col_width_basic'>날짜</th>
//                       <th className='col_width_basic'>시간</th>
//                       <th className='col_width_basic'>상태</th>
//                       <th className='col_width_basic'>고객성함</th>
//                       <th className='col_width_basic'>연락처</th>
//                   </tr>
//               </thead>
//               <tbody id='board_content'></tbody>
//           </table>

//           {/* 게시판 상세내용 */}
//           <div id='detail_content' className='detail_content' style={{display : "none"}}></div>

//           </div>
//           {/* 페이지선택 */}
//           <div className='page_number' id='page_number'>
//             {/* selectedTab 값을 PageContainer에 전달합니다. */}
//             <PageContainer tabType={selectedTab} />
//           </div>
//       </div>
//     );
// }


export default App;