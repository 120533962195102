import React from "react";


export default function HomeHeader() {


    return (
        <header className='msger-header'>
            <div className='msger-header-title'>
                <div className='header-logo-frame'>
                    <img src='/img/img_logo_active.png' style={{width: '48px'}} alt="Logo"></img>
                </div>
            </div>
        </header>
    )
}