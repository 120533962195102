import React from "react";


function PageNumber({pageNum, onGetList, isActive}) {
    return (
        <p
            className={`each_page_num ${isActive ? 'selected_page' : ''}`}
            onClick={() => onGetList(pageNum)}
        >
            {pageNum}
        </p>
    );
}

export default function PageNavigation(props) {
    const {onChangePage, paging} = props
    const {
        page,
        maxPage,
        btnRange,
        currentSet,
        startPage,
        totalSet
    } = paging


    return (
        <div id='page_number1' style={{display: 'flex'}}>
            {currentSet > 1 && (
                <button
                    className='page-prev'
                    onClick={() => {
                        onChangePage(page - 1)
                    }}
                >
                    이전
                </button>
            )}

            {Array.from({length: btnRange}, (_, i) => {
                const pageNumber = startPage + i;
                // 페이지 번호가 전체 페이지 수를 초과하지 않을 때만 렌더링
                return pageNumber <= maxPage && (
                    <PageNumber
                        key={i}
                        pageNum={pageNumber}
                        onGetList={() => {

                            onChangePage(pageNumber)
                        }}
                        isActive={pageNumber === page} // 현재 페이지 여부 확인
                    />
                );
            })}

            {totalSet > currentSet && (
                <button
                    className='page-next'
                    onClick={() => {
                        onChangePage(page + 1)
                    }}
                >
                    다음
                </button>
            )}
        </div>
    )
}