import React, {useEffect, useState} from 'react';
import '../css/Home.css';
import FileUploadComponent from '../Components/PdfUploader';
import {adminApi, authApi} from "../aaxios"
import useEnv from "../useEnv";
import LoginRoute from "../container/LoginRoute";
import HomeHeader from "../Components/home/HomeHeader";
import FAQContainer from "../container/home/FAQContainer";
import PDFContainer from "../container/home/PDFContainer";


const Home = () => {
    const [isChatCopied, setIsChatCopied] = useState(false);
    const [isAdminCopied, setIsAdminCopied] = useState(false);

    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [pdfFileNames, setPdfFileNames] = useState([]);
    const [filesEmbeddingStatus, setFilesEmbeddingStatus] = useState([]);
    const [lastClicked, setLastClicked] = useState(0);
    const [showPopup, setShowPopup] = useState(false); // 이미지 팝업 표시 여부
    const [currentImageUrl, setCurrentImageUrl] = useState('');  // 현재 팝업에 표시할 이미지 URL

    const {
        chatBotHomeUrl,
        chatbotAdminHomeUrl
    } = useEnv()

    const [pdfAlreadyRegistered, setPdfAlreadyRegistered] = useState(
        !!pdfFileNames && pdfFileNames.length > 0
    );


    // 챗봇, 관리자 페이지 URL 복사 기능 컨트롤러
    const handleCopyClipBoard = async (text, type) => {
        try {
            await navigator.clipboard.writeText(text);
            if (type === 'chat') {
                setIsChatCopied(true);
                setTimeout(() => setIsChatCopied(false), 3000);
            } else if (type === 'admin') {
                setIsAdminCopied(true);
                setTimeout(() => setIsAdminCopied(false), 3000);
            }
        } catch (err) {
            console.log('copy error >>', err);
            if (type === 'chat') {
                setIsChatCopied(false);
            } else if (type === 'admin') {
                setIsAdminCopied(false);
            }
        }
    };

    // 이미지 클릭 시 팝업 표시/숨김 토글
    const handleImageClick = (imageUrl) => {
        setCurrentImageUrl(imageUrl);  // 클릭된 이미지 URL 설정
        setShowPopup(true);            // 팝업 표시
    };

    useEffect(() => {
        adminApi.get(`/pdf/`)
            .then(response => {
                if (response.data && response.data.result.length > 0) {
                    const filesOnServer = response.data.result.map(file => file.file_name);
                    const statuses = response.data.result.map(file => file.status);
                    setPdfFileNames(filesOnServer);
                    setFilesEmbeddingStatus(statuses);
                    setUploadSuccess(true);
                    setPdfAlreadyRegistered(true);
                } else {
                    setPdfFileNames([]);
                    setFilesEmbeddingStatus([]);
                }
            })
            .catch(error => {
                console.error('Error from fetching PDF file names & embedding status >>', error);
                setPdfFileNames([]);
                setFilesEmbeddingStatus([]);
            });


    }, []);

    useEffect(() => {
        // 로그 출력용 시간 계산
        const now = new Date();
        const hours = ('0' + now.getHours()).slice(-2);
        const minutes = ('0' + now.getMinutes()).slice(-2);
        const seconds = ('0' + now.getSeconds()).slice(-2);
        const timeString = hours + ':' + minutes + ':' + seconds;

        const autoCheckStatus = async () => {
            await adminApi.get('/pdf/')
                .then((response) => {
                    const fileNames = response.data.result.map(file => file.file_name);
                    const newStatuses = response.data.result.map(file => file.status);
                    console.log('Fetched statuses >>', newStatuses, timeString);
                    setPdfFileNames(fileNames);
                    setFilesEmbeddingStatus(newStatuses);
                })
                .catch((error) => {
                    console.error('Error fetching data >>', error);
                });
        };

        if (filesEmbeddingStatus.includes('STARTED')) {
            const intervalId = setInterval(() => {
                autoCheckStatus();
            }, 5000);

            return () => clearInterval(intervalId);
        }

    }, [filesEmbeddingStatus]);

    return (
        <LoginRoute>
            <div className='wrap'>
                <HomeHeader/>
                {/* 가이드 아이콘 클릭 시 뜨는 팝업 이미지 */}
                {showPopup && (
                    <div className="image-popup-overlay" onClick={() => setShowPopup(false)} style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div className="image-popup-content" style={{
                            width: '430px',
                            position: 'relative',
                            padding: '20px',
                            background: 'white',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}>
                            <img
                                src={currentImageUrl}
                                alt="Popup"
                                style={{maxWidth: '100%', maxHeight: '100%'}}
                            />
                            <p>닫기</p>
                        </div>
                    </div>
                )}

                <PDFContainer handleImageClick={handleImageClick}/>
                <FAQContainer handleImageClick={handleImageClick}/>


                <div className='home-container'>
                    <p className='section-title'>챗봇</p>
                    <button className='link-btn' onClick={() => window.open(chatBotHomeUrl)}>챗봇 바로가기</button>
                    <button className='copy-btn'
                            onClick={() => handleCopyClipBoard(chatBotHomeUrl, 'chat')}>복사
                    </button>
                    {isChatCopied && <p style={{color: 'green'}}>복사되었습니다.</p>}
                </div>

                <div className='home-container'>
                    <p className='section-title'>챗봇 질문 관리 페이지</p>
                    <button className='link-btn' onClick={() => window.open(chatbotAdminHomeUrl)}>챗봇 질문
                        관리 페이지 바로가기
                    </button>
                    <button className='copy-btn'
                            onClick={() => handleCopyClipBoard(chatbotAdminHomeUrl, 'admin')}>복사
                    </button>
                    {isAdminCopied && <p style={{color: 'green'}}>복사되었습니다.</p>}
                </div>
            </div>
        </LoginRoute>
    );
}

export default Home;