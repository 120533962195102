import React, {useState} from "react";
import useLoadFAQ from '../../hooks/useLoadFAQ'

function FAQItem(props) {
    const {item, removeItem} = props
    return (
        <div>
            <input
                value={item.content}
            />

            <button onClick={() => removeItem(item)} style={{
                marginLeft: '10px',
                width: '45px',
                height: '40px',
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '10px',
                cursor: 'pointer'
            }}>
                삭제
            </button>
        </div>

    )
}


function FAQItemList(props) {
    const {removeItem} = props
    return (
        <div>
            {
                props.items.map((item) => {
                    return (
                        <FAQItem key={item.id} item={item} removeItem={removeItem}/>
                    )
                })
            }
        </div>
    )
}


function FAQInput(props) {
    const [value, setValue] = useState("")

    function onChange(e) {
        e.stopPropagation()
        setValue(e.target.value)
    }

    async function onSubmit() {
        if (value === '') return

        if (props.onSubmit) {
            const result = await props.onSubmit(value)
            if (result.ok) {
                setValue("")
                return
            }
            alert(result.message)
        }
    }

    async function onKeyUp(e) {
        if (e.key === "Enter" || e.keyCode === 13) {
            await onSubmit()
        }
    }

    return (
        <div style={{marginTop: '3rem'}}>
            <input
                value={value}
                onKeyUp={onKeyUp}
                onChange={onChange}
                placeholder='자주 찾는 질문을 입력하세요'
            />
            <button
                onClick={onSubmit}
                style={{
                    marginLeft: '10px',
                    width: '45px',
                    height: '40px',
                    backgroundColor: 'green',
                    color: 'white',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }}>
                입력
            </button>
        </div>
    )
}


export default function FAQContainer(props) {
    const {handleImageClick} = props
    const {addFaq, faqList, deleteFqa} = useLoadFAQ()


    async function removeFaq(faq) {
        const userConfirmed = window.confirm("정말 삭제 하시겠습니까?");
        if (!userConfirmed) return
        await deleteFqa(faq)

    }

    return (
        <div className='home-container'>
            <div className='title-info-area'>
                <p className='section-title'>자주 찾는 질문 List 추가하기</p>
                <img src={"/img/info_icon.png"} alt="more-info"
                     onClick={() => handleImageClick('/img/manual_02.svg')}/>
            </div>
            <div className='suggest-section'>
                <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <FAQItemList items={faqList} removeItem={removeFaq}/>
                    <FAQInput onSubmit={addFaq}/>
                </div>
            </div>
        </div>
    )
}