import React, {useEffect, useState} from "react";
import {toLocaleString} from "../../../infra/date/dataView";
import LoadingComponent from "../../LoadingComponent";
import useLoadChatMessage from "../../../hooks/useLoadChatMessage";

function ChatQuestion(props) {
    const {
        item,
        onRegen
    } = props
    return (
        <div className='each_question'>
            <img alt="Me" width="30" height="30" decoding="async" className="Home_usericon___BrVD"
                 src="/img/user.svg" style={{color: 'transparent', float: 'left'}}/>
            <p style={{paddingLeft: 50, margin: '16px 0'}}>{item['message']}</p>
            <button className='button_02' onClick={() => onRegen(item)}>AI 답변 재생성
            </button>
        </div>
    )
}

function ChatRegen(props) {
    const {
        item,
        status,
        regenResult,
        onSaveRegen,
        onCloseRegen
    } = props

    if (status === 'Loading') {
        return <LoadingComponent/>
    }
    if (status === "Saved") {
        return null
    }
    if (!regenResult) return null
    const style = {float: 'left', marginRight: '8px', cursor: 'pointer'};
    return (
        <div className='each_answer' id="regen_${answer_id}">
            <p>{regenResult.output}</p>
            <div style={{display: 'inline-block', marginTop: '8px'}}>
                <button className='button_02' style={style} onClick={() => onSaveRegen(item)}>답변적용</button>
                <button className='button_02' style={style} onClick={() => onCloseRegen(item)}>취소</button>
            </div>
        </div>
    )
}


function ChatAnswer(props) {
    const {item, onEdit, onChangeLike, like} = props
    const botMessages = item['bot_messages']
    const lastBotMessages = botMessages[0]
    return (
        <div className='each_answer'>
            <img alt="AI" width="35" height="35" decoding="async" className="Home_boticon__Xr0Q4"
                 src="/favicon_16x16.png" style={{ float: 'left', paddingRight: 10}}/>
            <p style={{
                paddingLeft: 50,
                textAlign: 'left',
                marginTop: 0,
                marginBottom: 30,
                lineHeight: 'calc(1ex / 0.32)'
            }}>{lastBotMessages['message']}</p>

            <div className='detail_buttonlist'>
                <button className='button_02' onClick={onEdit}>관리자 답변 수정</button>

                <img className='like_button' src={like === 'like' ? 'img/like_activate.png' : 'img/like_deactivate.png'}
                     alt='Like' onClick={async () => await onChangeLike("like")}/>
                <img className='like_button'
                     src={like === 'dislike' ? 'img/dislike_activate.png' : 'img/dislike_deactivate.png'} alt='Dislike'
                     onClick={async () => await onChangeLike("dislike")}/>
            </div>

            <div style={{display: 'flow-root'}}>

                <p style={{float: 'right'}}>시간: {toLocaleString(lastBotMessages['created_at'])}</p>
            </div>
        </div>
    )
}

function EditChatAnswer(props) {
    const {
        item,
        editStatus,
        onEdit,
        onClose,
    } = props
    const botMessages = item['bot_messages']
    const lastBotMessages = botMessages[0]
    const [editingAnswer, setEditingAnswer] = useState(lastBotMessages['message'])

    useEffect(() => {
        setEditingAnswer(lastBotMessages)
    }, [lastBotMessages]);

    if (editStatus !== "Editing") {
        return null
    }

    function onChangeTxt(e) {
        const newVal = e.target.value
        setEditingAnswer(newVal)
    }

    return (
        <div className='each_answer'>
              <textarea
                  className='edit_input_frame'
                  onChange={onChangeTxt}
                  defaultValue={lastBotMessages['message']}
              />
            <div style={{display: 'inline-block', marginTop: '8px'}}>
                <button
                    className='button_02'
                    style={{float: 'left', marginRight: '8px'}}
                    onClick={() => onEdit(editingAnswer)}
                >
                    답변완료
                </button>
                <button
                    className='button_02'
                    style={{float: 'left'}}
                    onClick={() => onClose()}
                >
                    취소
                </button>
            </div>
        </div>

    )
}

export function ChatMessage(props) {
    const {
        chatMessage,
        regenResult,
        regenStatus,
        onRegen,
        onCloseRegen,
        onCommitRegen,
        editStatus,
        onEdit,
        onCommitEdit,
        onCloseEdit,
        like,
        onChangeLike
    } = useLoadChatMessage(props)

    return (
        <div>
            <ChatQuestion item={chatMessage} onRegen={onRegen}/>
            <ChatRegen
                item={chatMessage}
                status={regenStatus}
                regenResult={regenResult}
                onSaveRegen={onCommitRegen}
                onCloseRegen={onCloseRegen}
            />

            <EditChatAnswer
                item={chatMessage}
                editStatus={editStatus}
                onEdit={onCommitEdit}
                onClose={onCloseEdit}
            />

            <ChatAnswer item={chatMessage}
                        onEdit={onEdit}
                        like={like}
                        onChangeLike={onChangeLike}
            />
        </div>
    )
}


export default function ChatMessageList(props) {
    const {list, onRegen} = props

    return (
        <>
            {
                list.map((item) => {
                    return (
                        <ChatMessage item={item} onRegen={onRegen}/>
                    )
                })
            }
        </>
    )
}