import {useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';
import {getToken, clearToken} from "../infra/token/token_storage"

const LoginRoute = ({children}) => {
    const [isLoading, setIsLoading] = useState(true);

    const isTokenExpired = (token) => {
        if (!token) return true;
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // 현재 시간 (초 단위)
        return decoded.exp < currentTime; // 만료 시간이 현재 시간보다 과거면 만료됨
    };

    useEffect(() => {
        const redirectLogin = () => {
            clearToken();
            window.location.href = '/'
        }
        const checkAndRefreshToken = () => {
            const token = getToken();
            if (!token) {
                return false
            }
            if (!token.refresh) {
                return false
            }
            if (isTokenExpired(token.refresh)) {
                return false
            }
            setIsLoading(false);
            return true
        };


        if (!checkAndRefreshToken()) {
            redirectLogin()
        }
    }, []);

    // 로딩 중일 때는 아무것도 렌더링하지 않음
    if (isLoading) {
        return null;  // 또는 로딩 스피너 등을 렌더링할 수 있음
    }

    return children;
};

export default LoginRoute;
