export function toLocaleString(isoStr) {
// 한국 시간대 변환 및 보기 좋은 형식으로 출력
    const correctedIsoString = isoStr.split('.')[0] + 'Z';
    const date = new Date(correctedIsoString);

// 한국 시간대로 변환하여 간단하게 출력
    const options = {
        timeZone: "Asia/Seoul",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        weekday: "short", // 요일을 짧게 표기 (예: 월, 화, 수)
        hour12: false
    };
    let formattedDateTime = date.toLocaleString("ko-KR", options)
        .replace(/\.\s/g, '/')   // "년. 월. 일." 패턴을 "/"로 변환
        .replace(/,\s/g, '(')    // 요일 앞에 괄호 추가
        .replace(/\)$/, ')')     // 요일 뒤 괄호 추가
    return formattedDateTime
}