import React from "react";
import "../../App.css"
import {toLocaleString} from "../../infra/date/dataView";

function statusToString(status) {

    if (status === "unidentified") {
        return "미확인"
    }
    if (status === "confirm") {
        return "검토 완료"
    }
    if (status === 'edit') {
        return "수정 필요"
    }
    return "미확인"
}


export function TableItem(props) {
    // let {
    //     session_id,
    //     content,
    //     date,
    //     time,
    //     status,
    //     user_name,
    //     user_number,
    //     onClick,
    //     index,
    // } = props
    const {
        index,
        id,
        created_at,
        updated_at,
        deleted,
        status,
        first_message,
        onClick,
    } = props

    function handleClick(e) {
        e.stopPropagation()
        onClick && onClick()
    }

    return (
        <tr key={id}
            onClick={handleClick}>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{index + 1}</td>
            <td className='col_width_content' style={{color: 'var(--black, #000)'}}>{first_message}</td>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{toLocaleString(created_at)}</td>
            <td className='col_width_basic' style={{color: 'var(--red, #DB524C)'}}>{statusToString(status)}</td>
        </tr>
    )
}


export default function TableBody(props) {
    const {items, onClick} = props;
    return (
        <table style={{width: '95%'}} className='table_frame' id='TableBody'>
            <thead className='TableBodyThead'>
            <tr>
                <th className='col_width_basic'>글번호</th>
                <th className='col_width_content'>내용</th>
                <th className='col_width_basic'>시간</th>
                <th className='col_width_basic'>상태</th>
            </tr>
            </thead>
            <tbody id='TableBodyTBody'>{
                items.map((item, key) => {
                    return (
                        <TableItem {...item} index={key}
                                   key={key}
                                   onClick={() => onClick({
                                       ...item,
                                       index: key
                                   })}
                        />
                    )
                })
            }</tbody>
        </table>
    )
}