import React, {useEffect} from "react";


export function TableNavItem(props) {
    const {onClick, id, label, status} = props
    let bgColor = '#6e8cc1'
    if (status === id) {
        bgColor = '#2c549b'
    }

    return (
        <div className='tap_header' id={id}
             style={{background: bgColor, borderRadius: '0 0 20px 20px'}}
             onClick={(e) => {
                 e.stopPropagation()
                 onClick && onClick()
             }}>{label}
        </div>
    )
}


export default function TableNav(props) {
    const {items, onClick, status} = props

    return (
        <>
            {items.map((item, index) => {
                return (
                    <TableNavItem {...item}
                                  onClick={() => {
                                      onClick && onClick(item)
                                  }}
                                  status={status}
                                  key={index+""+status}
                    />
                )
            })}
        </>
    )

}