import {RecoilRoot, atom, useRecoilState, selector, useRecoilValue} from 'recoil';

// 1. Atom: 전역 상태를 정의
const listEventAtom = atom({
    key: 'MainListEvent', // 고유한 key 값
    default: 'Loading',      // 초기값 [Loading(로딩 중), Loaded(로딩 완료) ,Refresh(재시도 요청) ]
});

/*
* main 페이지의 목록들 관련 이벤트 전역 관리
* */
export default function useEditMainListState() {
    const [mainListEvent, setMainListState] = useRecoilState(listEventAtom);


    function dispatchRefresh() {
        setMainListState("Refresh");
    }

    function dispatchLoaded() {
        setMainListState("Loaded");
    }

    function dispatchLoadFailed() {
        setMainListState("LoadFail");
    }

    function isRefresh() {
        return mainListEvent === "Refresh"
    }

    function isLoading(){
        return mainListEvent==="Loading"
    }

    return {
        mainListEvent,        // 상태 값
        dispatchRefresh,
        dispatchLoaded,
        dispatchLoadFailed,
        isRefresh,
        isLoading
    };
}