import {useEffect, useState} from "react";
import {authApi} from "../aaxios";

async function runViewReq({
                              message,
                              query_type,
                          }) {
    try {
        const url = `/admin/run-view`
        const res = await authApi.post(url, {
            message,
            query_type
        })
        return res['data']['data']
    } catch (e) {
        console.log(e)
    }
}

async function regenReq({
                            chat_id,
                            message_id,
                            run_view
                        }) {
    try {
        const url = `/admin/chat/${chat_id}/message/${message_id}/regen`
        const res = await authApi.post(url, {
            run_view
        })
        return res['data']['data']
    } catch (e) {
        console.log(e)
    }
}


async function editReq({
                           chat_id,
                           message_id,
                           message
                       }) {
    try {
        const url = `/admin/chat/${chat_id}/message/${message_id}/edit`
        const res = await authApi.post(url, {
            message
        })
        return res['data']['data']
    } catch (e) {
        console.log(e)
    }
}

async function likeReq({
                           like,
                           chat_id,
                           message_id,
                           chat_bot_message_id
                       }) {
    try {
        const url = `/admin/chat/${chat_id}/message/${message_id}/bot_message/${chat_bot_message_id}/like`
        const res = await authApi.post(url, {
            like
        })
        return res['data']
    } catch (e) {

    }

}

export default function useLoadChatMessage(props) {
    const [chatMessage, setChatMessage] = useState(props.item)
    const [regenStatus, setRegenStatus] = useState("None")
    const [regenResult, setRegenResult] = useState(null)
    const [editStatus, setEditStatus] = useState("None")
    const [like, setLike] = useState("unknown")

    useEffect(() => {
        if (!props) return
        if (!props.item) return
        console.log("메시지 꼬라지가>?>> ", chatMessage)
        setChatMessage(props.item)
        setLike(props.item['bot_messages'][0]['like_status'])
    }, [props.item]);

    async function onRegen() {
        setRegenStatus("Loading")

        const res = await runViewReq({
            message: chatMessage['message'],
            query_type: "text"
        })
        setRegenResult(res)
        setRegenStatus("Loaded")
    }

    async function onCloseRegen() {
        setRegenStatus("None")
        setRegenResult(null)
    }

    async function onCommitRegen() {
        if (regenResult) {
            const res = await regenReq({
                chat_id: chatMessage['chat_id'],
                message_id: chatMessage['id'],
                run_view: regenResult,
            })
            setChatMessage({
                ...chatMessage,
                bot_messages: [res]
            })
            setRegenStatus("None")
            setRegenResult(null)
        }
    }

    function onCloseEdit() {
        setEditStatus("None")
    }

    function onEdit() {
        setEditStatus("Editing")
    }

    async function onCommitEdit(txt) {
        const res = await editReq({
            chat_id: chatMessage['chat_id'],
            message_id: chatMessage['id'],
            message: txt
        })
        if (res) {
            setEditStatus("None")
            setChatMessage({
                ...chatMessage,
                bot_messages: [res]
            })
        }
    }

    async function onChangeLike(nextLike) {
        // api 호출
        // toggle 기능.
        if (nextLike === like) {
            nextLike = "unknown"
        }
        const res = await likeReq({
            chat_id: chatMessage['chat_id'],
            message_id: chatMessage['id'],
            chat_bot_message_id: chatMessage['bot_messages'][0]['id'],
            like: nextLike
        })
        if (res.ok) {
            setLike(nextLike)
        }
    }

    return {
        chatMessage,
        onRegen,
        regenStatus,
        regenResult,
        editStatus,
        onCloseRegen,
        onCommitRegen,
        onCloseEdit,
        onEdit,
        onCommitEdit,
        like,
        onChangeLike
    }
}