import useLoadChat from "../../../hooks/useLoadChat";
import ChatEditTab from "./ChatEditTab";
import ChatMessageList from "./ChatMessageList";

export default function ChatEditor(props) {
    const {chat, onClose, onRefresh} = props
    const {chatMessageList, onChangeStatus} = useLoadChat({chatId: chat ? chat.id : null})
    if (!chat) return null


    async function handleOnChangeStatus(status) {
        const res = await onChangeStatus(status)
        if (res.ok) {
            await onRefresh()
        }
    }

    return (

        <div id='detail_content_v2' className='detail_content'>
            <ChatEditTab date={chat['created_at']} onClose={onClose}
                         onDelete={async () => {
                             await handleOnChangeStatus("deleted")
                         }}
                         onConfirm={async () => {
                             await handleOnChangeStatus("confirm")
                         }}

            />
            <ChatMessageList list={chatMessageList}/>
        </div>
    )

}