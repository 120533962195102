import React from 'react';

export default function LoadingComponent() {

    return (
        <div
            style={{
                backgroundImage: "url('img/spinner_03.gif')",
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                width: '20%',
                height: '50px',
                borderRadius: '8px',
                margin: 'auto'
            }}
        ></div>
    );
}

